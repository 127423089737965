import React from "react";
import { Typography, Box } from "@mui/material";

const Footer = () => {
  return (
    <footer className="footer">
      <Box
        sx={{
          backgroundColor: "#003366",
          color: "white",
          padding: 4,
          marginTop:{md:"0",xs:"25"}
         
        }}
      >
        
        <Typography
          variant="body2"
          sx={{
            color: "white",
            textAlign: "center", 
            paddingLeft: 2,
          }}
        >
          © 2024 Medical College in Bangalore Pvt. Ltd. All Rights Reserved
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;