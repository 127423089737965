import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import DoctorImage from '../asserts/Blog.jpeg'; // Import the image

const Blog = () => {
  return (
    <Grid
      container
      sx={{
        maxWidth: '2000px',
        margin: '0 auto',
        padding: '10px 60px 40px 0', // Removed left padding (set to 0)
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
        marginTop: '-15px',
      }}
      spacing={4}
    >
      {/* Left Side: Image */}
      <Grid
        item
        xs={12} sm={6} // For small screens, image will take full width, for medium screens it takes half the width
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: { xs: '20px', sm: '0' }, // Add margin on small screens for spacing
        }}
      >
        <img
          src={DoctorImage} // Use the imported image path
          alt="Medical College"
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </Grid>

      {/* Right Side: Text */}
      <Grid
        item
        xs={12} sm={6} // For small screens, text will take full width, for medium screens it takes half the width
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start', // Align text to the left
          textAlign: 'left', // Align text left for better readability
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            fontSize: '30px',
            color: '#3498db',
            marginBottom: '16px',
            textDecoration: 'underline',
          }}
        >
          MEDICAL COLLEGES IN BANGALORE
        </Typography>
        <Divider
          sx={{
            width: '50px',
            height: '4px',
            marginBottom: '16px',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: '16px',
            color: '#34495e',
            lineHeight: 1.8,
          }}
        >
          Bangalore, the capital city of Karnataka, is known for its diverse educational landscape and has earned a reputation as a hub for medical education in India. The city is home to some of the top medical colleges in the country, offering comprehensive medical courses at undergraduate, postgraduate, and diploma levels. These institutions are renowned for their academic excellence, state-of-the-art infrastructure, and access to world-class healthcare facilities.

          Some of the premier medical colleges in Bangalore include Bangalore Medical College, St. John’s Medical College, MS Ramaiah Medical College, and Vydehi Institute of Medical Sciences. These colleges are equipped with modern teaching facilities, highly experienced faculty, and a strong emphasis on clinical exposure, research, and innovation in the medical field.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Blog;
