import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header"
import Banner from "./components/Banner";
import Coursecards from "./components/Coursecards";
import Blog from "./components/Blog";
import Footer from "./components/Footer";
import Apply from "./components/Apply";

const App = () => {
  return (
    <Router>
      <Header/>
      <Banner/>
      <Coursecards/>
      <Blog/>
      <Apply/>
      <Footer/>
    </Router>
  );
};
export default App;
