import React from 'react';
import { Box, Card, CardContent, Typography, Button, Grid } from '@mui/material';
import { motion } from 'framer-motion';

// Import the images correctly
import img1 from '../asserts/Ambedkar.jpeg';
import img2 from '../asserts/Bangalore medical.jpeg';
import img3 from '../asserts/Saptagiri.jpeg';
import img4 from '../asserts/Ramaiha.jpeg';

const testimonials = [
  {
    id: 1,
    College: "AMBEDKAR MEDICAL COLLEGE",
    text: "Ambedkar Medical College, established in 1981, is a prominent private medical institution in Bangalore, Karnataka.",
    image: img1,
    link: "https://www.getmycollege.com/colleges?state=karnataka",
  },
  {
    id: 2,
    College: "BANGALORE MEDICAL COLLEGE",
    text: "Bangalore Medical College and Research Institute is one of the most prestigious medical colleges.",
    image: img2,
    link: "https://www.getmycollege.com/colleges?state=karnataka",
  },
  {
    id: 3,
    College: "SAPTAGIRI MEDICAL COLLEGE",
    text: "Saptagiri Medical College, located in Bangalore, is a premier institution known for medical education.",
    image: img3,
    link: "https://www.getmycollege.com/colleges?state=karnataka",
  },
  {
    id: 4,
    College: "REVA MEDICAL COLLEGE",
    text: "REVA Medical College, established in 2017, is a private medical college located in Bangalore, Karnataka.",
    image: img4,
    link: "https://www.getmycollege.com/colleges?state=karnataka",
  },
];

const CourseCards = () => {
  return (
    <Box p={3} sx={{ backgroundColor: '#f2f2f2', minHeight: '100vh' }}>
      {/* Heading with underline */}
      <Box sx={{ textAlign: 'center', mb: 4, position: 'relative' }}>
        <Typography
          variant="h4"
          color="primary"
          sx={{
            fontWeight: 900,
            letterSpacing: 2,
            textTransform: 'uppercase',
            mb: 1,
          }}
        >
          Medical Colleges
        </Typography>
        {/* Underline */}
        <Box
          sx={{
            width: '150px',
            height: '4px',
            backgroundColor: 'primary.main',
            margin: '0 auto',
            borderRadius: 2,
          }}
        />
        <Typography variant="body1" sx={{ mt: 1, color: '#555' }}>
          Explore top medical colleges offering world-class education.
        </Typography>
      </Box>

      {/* Cards Grid */}
      <Grid container spacing={3} justifyContent="center">
        {testimonials.map((testimonial) => (
          <Grid item key={testimonial.id} xs={12} sm={6} md={3}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Card
                sx={{
                  boxShadow: 3,
                  height: 350,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: 2,
                }}
              >
                {/* Image */}
                <Box
                  component="img"
                  src={testimonial.image}
                  alt={testimonial.College}
                  sx={{
                    width: '100%',
                    height: 150,
                    objectFit: 'cover',
                    borderRadius: 1,
                    mb: 2,
                  }}
                />
                {/* Content */}
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', mb: 1, fontSize: '16px' }}
                  >
                    {testimonial.College}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '14px' }}>
                    {testimonial.text}
                  </Typography>
                </CardContent>
                {/* Button */}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => window.open(testimonial.link, '_blank')}
                >
                  View More
                </Button>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Explore More Button */}
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#008ae6', // Custom green color
            color: '#fff', // White text
            padding: '12px 24px',
            '&:hover': {
              backgroundColor: '#008ae6', // Darker green on hover
            },
          }}
          onClick={() => window.open("https://www.getmycollege.com", '_blank')}
        >
          Explore More Colleges
        </Button>
      </Box>
    </Box>
  );
};

export default CourseCards;
