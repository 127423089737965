import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  InputLabel,
  TextField,
} from "@mui/material";

const styles = {
  body: {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f0f0f5", // Light background color
    height: "auto",
    width: "auto",
  },
  cardContainer: {
    margin: "50px auto",
    maxWidth: "80%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Center align card content
  },
  leftSection: {
    padding: "20px",
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  cardTitle: {
    fontWeight: "bold",
    marginBottom: "20px",
  },

  applyButton: {
    backgroundColor: "#00004d",
    color: "white",
    padding: "10px 30px", // Reduced padding
    fontSize: "14px", // Smaller font size
    borderRadius: "10px",
    textAlign: "center",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "green",
    },
    margin: "20px auto", // Center the button horizontally
  },

  dialogContent: {
    border: "2px solid #4547e5",
    padding: "20px",
    minWidth: "400px",
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "15px",
  },
  dialogDivider: {
    height: "2px",
    backgroundColor: "black",
    marginBottom: "20px",
  },
  cancelButton: {
    backgroundColor: "red",
    color: "white",
    padding: "10px 20px",
    fontSize: "14px",
    borderRadius: "px",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "gray",
    },
  },
  submitButton: {
    backgroundColor: "green",
    color: "white",
    padding: "10px 20px",
    fontSize: "14px",
    borderRadius: "5px",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "gray",
    },
  },
};

const Apply = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });

  const handleOpen = () => setFormOpen(true);
  const handleClose = () => setFormOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    console.log(formData);
    setFormOpen(false);
  };

  return (
    <Grid container sx={styles.body}>
      <Grid container sx={styles.cardContainer} justifyContent={"center"}>
        <Grid item xs={12} sx={styles.leftSection}>
          <Typography variant="h4" sx={styles.cardTitle}>
            Explore Medical College
          </Typography>
          <Typography paragraph>
            A <b>Medical college offers business administration courses</b>, providing students with foundational knowledge in management, marketing, finance, and entrepreneurship.
          </Typography>
          <Typography component="ul" style={{ textAlign: "left", marginLeft: "15px" }}>
            <li>BANGALORE MEDICAL COLLEGE</li>
            <li>SAPTAGIRI MEDICAL COLLEGE</li>
            <li>Dr AMBEDKAR MEDICAL COLLEGE</li>
            <li>M S RAMAIH MEDICAL COLLEGE</li>
            <li>REVA MEDICAL COLLEGE</li>
            <li>KEMPEGOWDA INSTITUTE OF MEDICAL SCIENCE</li>
            <li>ST JOHNS MEDICAL COLLEGE</li>
            <li>RAJARAJESWARI MEDICAL COLLEGE</li>
            <li>MVJ MEDICAL COLLEGE</li>
          </Typography>
          <Button sx={styles.applyButton} onClick={handleOpen}>
            Apply Now
          </Button>
        </Grid>
      </Grid>

      <Dialog open={formOpen} onClose={handleClose}>
        <DialogContent sx={styles.dialogContent}>
          <Typography variant="h6" textAlign="center" sx={styles.dialogTitle}>
            Application for Medical College
          </Typography>
          <Divider sx={styles.dialogDivider} />

          <InputLabel>Name</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
          />

          <InputLabel>Phone Number</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter your phone number"
          />

          <Grid container justifyContent="center" mt={3} gap={2}>
            <Button sx={styles.cancelButton} onClick={handleClose}>
              Cancel
            </Button>
            <Button sx={styles.submitButton} onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default Apply;
