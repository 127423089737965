import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../asserts/medicalcollegesinbangalore.com.png"; 

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background: "#003366",
          mb: 3,
          px: 2,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Logo */}
            <Box sx={{ display: "flex", alignItems: "center", width:{md:100,xs:50} }}>
              <img
                src={logo} 
                alt="Logo"
                style={{
                  width: "80px" , 
                  height: "80px",
                  borderRadius: "50%", 
                  objectFit: "cover", 
                  paddingTop: "10px",  // Top padding
                  paddingBottom: "10px", // Bottom padding
                }}
              />
            </Box>

            {/* Desktop Menu */}
            <Box
              sx={{
                display: { md: "flex", xs: "none" },
                justifyContent: "flex-end", 
                flexGrow: 1,
              }}
            >
              <Button
                component={Link}
                to="/"
                color="inherit"
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.1)" },
                  mr: 2,
                }}
              >
                Home
              </Button>

              <Button
                href="https://www.getmycollege.com/about-us"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.1)" },
                  mr: 2,
                }}
              >
                About
              </Button>

              <Button
                href="https://www.getmycollege.com/colleges?state=karnataka"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.1)" },
                  mr: 2,
                }}
              >
                Medical Colleges in Bangalore
              </Button>

              <Button
                href="https://www.getmycollege.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.1)" },
                  mr: 0,
                }}
              >
                Contact Us
              </Button>
            </Box>

            {/* Mobile Menu Icon */}
            <Box sx={{ display: { md: "none", xs: "flex" } }}>
              <IconButton
                color="inherit"
                onClick={handleMenuOpen}
                aria-label="open menu"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem component={Link} to="/" onClick={handleMenuClose}>
                  Home
                </MenuItem>
                <MenuItem
                  href="https://www.getmycollege.com/about-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleMenuClose}
                >
                  About
                </MenuItem>
                <MenuItem
                  href="https://www.getmycollege.com/colleges?state=karnataka"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleMenuClose}
                >
                  Medical Colleges in Bangalore
                </MenuItem>
                <MenuItem
                  href="https://www.getmycollege.com/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleMenuClose}
                >
                  Contact Us
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
